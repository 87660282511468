import Button from "@mui/material/Button";
import React from "react";

interface PrimaryButtonInterface {
  color?;
  fullWidth?: boolean | false;
  id?: string | null;
  text: string | null;
  handleClick?: any;
  icon?: any;
  iconPosition?: string;
  variant?: string;
  size?: string;
  position?: string;
  disabled?: boolean;
  className?: string;
  handleType?: any;
}

const PrimaryButton = ({
  fullWidth,
  id,
  text,
  icon,
  iconPosition = "left",
  variant = "primary",
  size = "normal",
  position = "left",
  disabled,
  handleType,
  className,
  handleClick,
}: PrimaryButtonInterface) => {
  return (
    <Button
      fullWidth={fullWidth}
      sx={{
        color: "#fffbf8",
        backgroundColor: "#6c64ff",
        boxShadow: "none",
        fontFamily: '"DM Sans", sans-serif',
        fontSize: "15px",
        fontWeight: 500,
        textTransform: "none",
        width: fullWidth ? "100%" : "max-content",
        gap: "7px",
        letterSpacing: 0,
        maxHeight: "38px",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#d7d7d7",
          boxShadow: "none",
        },
        "&:focus": {
          backgroundColor: "#d7d7d7",
          boxShadow: "none",
        },
        "& svg": {
          width: "18px",
          height: "18px",
        },
        "&.size--small": {
          fontSize: "14px",
          padding: "6px 10px",
        },
        "&.secondary-btn": {
          backgroundColor: "#eff3ff",
          color: "#6c64ff",
        },
        "&.filter-btn": {
          width: "auto",
          minWidth: "max-content",
          height: "38px",
          fontFamily: '"DM Sans", sans-serif',
          fontWeight: 400,
          backgroundColor: "#fff",
          fontSize: "15px",
          color: "#3d4572",
          boxShadow: "none",
          borderRadius: "10px",
          border: "1px solid #e8e8ee",
        },
        "&.position--right": {
          marginLeft: "auto",
        },
        "&.danger-btn": {
          backgroundColor: "#ff82a6",
          color: "#8e2d4a",
        },
        "&.success-btn": {
          backgroundColor: "#81c784",
          color: "#ffffff",
          width: "100%",
        },
      }}
      className={className}
      variant="contained"
      onClick={handleClick}
      type={handleType}
      id={id}
    >
      {iconPosition && iconPosition === "left" && icon}
      {text}
      {iconPosition && iconPosition === "right" && icon}
    </Button>
  );
};

export default PrimaryButton;
