import getModelVersion from "../../../api/get-model-version";
import React, { useEffect, useState } from "react";

const ModelSelect: React.FC<{ models: any[] }> = ({ models }) => {
  const [selectedModelId, setSelectedModelId] = useState(models[0] ? models[0].id : "");

  useEffect(() => {
    const fetchModelVersion = async () => {
      if (selectedModelId) {
        const params = {
          auth: Office.context.roamingSettings.get("straact_access_token"),
          model: selectedModelId,
          creator: Office.context.roamingSettings.get("karlamail_uid"),
        };
        await getModelVersion(params);
      }
    };
    fetchModelVersion();
  }, [selectedModelId]);

  const handleChange = async (event) => {
    const modelID = event.target.value;
    setSelectedModelId(modelID);
  };

  return (
    <div>
      <select id="karlamail-models__select" onChange={handleChange} value={selectedModelId} aria-label="Model Selector">
        {models &&
          models.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name} ({model.id})
            </option>
          ))}
      </select>
    </div>
  );
};

export default ModelSelect;
