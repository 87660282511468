import React from "react";
import PrimaryButton from "../mui/PrimaryButton";
import ModelSelect from "./ModelSelect";

interface SettingsMenuProps {
  models: any[];
  onResetAuth: () => void;
  onResetGraph: () => void;
  graphAuthenticated: Boolean;
  karlaAuthenticated: Boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  models,
  onResetAuth,
  onResetGraph,
  graphAuthenticated,
  karlaAuthenticated,
}) => {
  return (
    <>
      <div id="authentication-reset">
        {karlaAuthenticated && (
          <PrimaryButton
            fullWidth={true}
            className="danger-btn"
            text="Reset Karla authentication"
            handleClick={onResetAuth}
          />
        )}
        {graphAuthenticated && (
          <PrimaryButton
            fullWidth={true}
            className="danger-btn"
            text="Reset Microsoft authentication"
            handleClick={onResetGraph}
          />
        )}
      </div>
      <div id="karlamail-models">
        <label>Select a model:</label>
        <ModelSelect models={models}></ModelSelect>
      </div>
    </>
  );
};

export default SettingsMenu;
