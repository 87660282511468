import { createMailWithSuggestion } from "../../../api/prompt-answer-suggestion";
import PrimaryButton from "../mui/PrimaryButton";
import React from "react";

let root = null;

export function deconstructUseAnswerButtonComponent() {
  if (root) {
    root.unmount();
    root = null;
  }
}

function handleClick(answerHtml) {
  createMailWithSuggestion(answerHtml);
  deconstructUseAnswerButtonComponent();
}

interface AnswerButtonProps {
  answerHtml: string;
  conversation: any;
}

const UseAnswerButton: React.FC<AnswerButtonProps> = ({ answerHtml }) => {
  return (
    <>
      <PrimaryButton id="use-answer-button" text="Use answer" handleClick={() => handleClick(answerHtml)} />
    </>
  );
};

export default UseAnswerButton;
