import React, { useState, useEffect } from "react";
import PrimaryButton from "../mui/PrimaryButton";
import DisplayError from "../mui/snackbars/DisplayError";
import DisplaySuccess from "../mui/snackbars/DisplaySuccess";
import AuthenticationService from "../../../api/authentication-service";
import "../../taskpane.css";

const AuthenticationForm: React.FC<{ onAuthenticated: () => void; isVisible: boolean }> = ({
  onAuthenticated,
  isVisible,
}) => {
  const [api_key, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [error, setError] = useState<{ reason: string } | null>(null);
  const [message, setMessage] = useState<{ reason: string } | null>(null);

  const authenticationService = new AuthenticationService();

  const handleAuthenticate = async (event) => {
    event.preventDefault();
    await authenticationService.authenticate(
      api_key,
      secretKey,
      (authenticated: Boolean) => {
        authenticated ? onSuccess() : setError({ reason: "Authentication failed" });
      },
      true
    );
  };

  const onSuccess = () => {
    setMessage({ reason: "Successfully authenticated" });
    onAuthenticated();
  };

  const resetForm = () => {
    setApiKey("");
    setSecretKey("");
  };

  useEffect(() => {
    if (isVisible) {
      resetForm();
    }
  }, [isVisible]);

  return (
    <>
      <div id="authentication">
        {isVisible && (
          <div>
            <p>Please input the API-key and Secret-key provided by Karla</p>
            <span>
              <p>If you are not a customer of ours you can read more about the service at: </p>
              <a href="https://getkarla.ai" target="_blank">https://getkarla.ai</a>
            </span>
            <span>
              <p>For any further questions contact us via the information found at: </p>
              <a href="https://getkarla.ai/kontakt/" target="_blank">https://getkarla.ai/kontakt/</a>
            </span>
          </div>
        )}
        {isVisible && (
          <form id="authentication-form" onSubmit={handleAuthenticate}>
            <input
              type="text"
              id="authentication-form-api-key-field"
              placeholder="Your API-Key"
              name="apiKey"
              onChange={(e) => setApiKey(e.target.value)}
            />
            <input
              type="text"
              id="authentication-form-secret-key-field"
              placeholder="Your Secret-Key"
              name="secretKey"
              onChange={(e) => setSecretKey(e.target.value)}
            />
            <PrimaryButton text="Verify" handleType={SubmitEvent} />
          </form>
        )}
        {message && (
          <DisplaySuccess display_message={message} snackbarHandleClose={() => setMessage(null)}></DisplaySuccess>
        )}
        {error && <DisplayError display_message={error} snackbarHandleClose={() => setError(null)}></DisplayError>}
      </div>
    </>
  );
};
export default AuthenticationForm;
