import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/Root";

let isOfficeInitialized = false;

//for slow networks
Office.initialize = () => {};

const title = "Karla Mail";

const render = (Component) => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<Component title={title} isOfficeInitialized={isOfficeInitialized} />);
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});
