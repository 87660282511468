import React, { useState, useEffect } from "react";
import axios from "axios";

interface Sender {
  name: string;
  address: string;
  timeCreated: string;
}

const MetaData: React.FC<{ item }> = ({ item }) => {
  const [senderInfo, setSenderInfo] = useState<Sender | null>(null);
  const [error, setError] = useState<string | null>(null);

  async function getConversation(): Promise<any | null> {
    try {
      const conversationId = Office.context.mailbox.item.conversationId;
      const token: string = Office.context.roamingSettings.get("msal_access_token");
      const getConversationIdUrl: string = `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`;
      const response = await axios.get(getConversationIdUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data && response.data.value && response.data.value.length > 0) {
        console.log("Response", response);
        return response.data.value[0];
      }
    } catch (e) {
      console.error("Error when getting conversation", e);
    }
  }

  async function getMessageSentDate() {
    try {
      // const data = await getConversation();
      const data = item;
      return reformatDate(data.sentDateTime);
    } catch (e) {
      console.error("Error when getting message creation date", e);
    }
  }

  function reformatDate(isoDateString: string) {
    const date: Date = new Date(isoDateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formattedDate: string = new Intl.DateTimeFormat("en-GB", options).format(date);

    console.log("Formatted date: ", formattedDate);
    return formattedDate;
  }

  async function getMessageSender() {
    try {
      // const data = await getConversation();
      const data = item;
      const senderName = data.sender.emailAddress.name;
      const senderAddress = data.sender.emailAddress.address;
      const timeReceived = await getMessageSentDate();

      console.log("Data: ", data);
      console.log("Time ", timeReceived);

      const sender: Sender = {
        name: senderName,
        address: senderAddress,
        timeCreated: timeReceived,
      };

      return sender;
    } catch (e) {
      console.error("Error when getting message sender", e);
    }
  }

  useEffect(() => {
    getMessageSender()
      .then((sender: Sender) => {
        setSenderInfo(sender);
      })
      .catch((e) => {
        console.error("Error: ", e);
        setError(e);
      });
  }, [item]);

  if (!senderInfo) {
    return <p></p>;
  }

  const senderName: string = senderInfo.name;
  const senderAddress: string = senderInfo.address;
  const timeCreated: string = senderInfo.timeCreated;

  return (
    <>
      <p>
        {senderName} ({senderAddress})
      </p>
      <p>{timeCreated.toString()}</p>
    </>
  );
};

export default MetaData;
