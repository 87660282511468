const HOST = window.location.hostname;
const NODE_ENV = process.env.NODE_ENV || "development";

type Environment = {
  ENV: string;
  SITE_HOST: string;
  APP_ROOT: string;
  API_ENDPOINTS: any;
};

const production: Environment = {
  ENV: "production",
  SITE_HOST: HOST,
  APP_ROOT: "https://outlook.karlamail.com",
  API_ENDPOINTS: {
    STRAACT_FLASK_API: "https://api.straact.com/v1",
    //STRAACT_FLASK_API: "https://api.staging.straact.com/api/v1",
  },
};

const stage: Environment = {
  ENV: "staging",
  SITE_HOST: HOST,
  APP_ROOT: "https://karlamail-outlook-staging.web.app",
  API_ENDPOINTS: {
    STRAACT_FLASK_API: "https://api.staging.straact.com/v1",
  },
};

const local: Environment = {
  ENV: "local",
  SITE_HOST: HOST,
  APP_ROOT: "http://localhost:3005",
  API_ENDPOINTS: {
    STRAACT_FLASK_API: "http://127.0.0.1:5050/v1",
  },
};

const getConstant = (name: keyof Environment) => {
  // If development / localhost then return local
  // Otherwise, use the HOST variable to return either stage or prod
  console.log("Environment: ", NODE_ENV);

  switch (NODE_ENV) {
    case "development":
      return local[name];
    case "staging":
      return stage[name];
    default:
      return production[name];
  }
};

export const ENV = getConstant("ENV");
export const SITE_HOST = getConstant("SITE_HOST");
export const APP_ROOT = getConstant("APP_ROOT");
export const API_ENDPOINTS = getConstant("API_ENDPOINTS");
