import React, { useState, useEffect, useCallback, useRef } from "react";
import AuthenticationForm from "./security/AuthenticationForm";
import Banner from "./ux/Banner";
import SettingsMenu from "./settings/SettingsMenu";
import MetaData from "./ux/MetaData";
import getModels from "../../api/get-models";
//import Answer from "./answer/Answer";
import PrimaryButton from "./mui/PrimaryButton";
import promptAnswerSuggestion from "./../../api/prompt-answer-suggestion";
import DisplayError from "./mui/snackbars/DisplayError";
import DisplaySuccess from "./mui/snackbars/DisplaySuccess";
import DisplayWarning from "./mui/snackbars/DisplayWarning";
import AuthenticationService from "../../api/authentication-service";
import axios from "axios";
import { logoutFromO365, signInO365 } from "../security/graph-api/graph-authenticator";
import Answer from "./answer/Answer";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const Root: React.FC = () => {
  const [models, setModels] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [answerHtml, setAnswerHtml] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [writeAnswerButtonText, setWriteAnswerButtonText] = useState("Write answer suggestion");
  const [graphAuthenticatorInitialized, setGraphAuthenticatorInitialized] = useState(false);
  const [graphAuthenticated, setGraphAuthenticated] = useState(false);
  const [item, setItem] = useState(null);

  const accessTokenRef = React.useRef<string | null>(null);
  const userNameRef = React.useRef<string | null>(null);

  const setToken = (accessToken: string) => {
    accessTokenRef.current = accessToken;
  };

  const setUserName = (userName: string) => {
    userNameRef.current = userName;
  };

  const displayError = (error: string) => {
    setError({ reason: error });
    setWarning(null);
    setMessage(null);
  };

  const login = useCallback(async () => {
    var token,
      userName = await signInO365(displayError);
    console.log(token);
    console.log(userName);
    if (token !== "undefined") {
      setGraphAuthenticated(true);
      const item = await getConversation();
      setItem(item);
    }
  }, []);

  const logout = useCallback(async () => {
    await logoutFromO365(setUserName, userNameRef.current, displayError);
  }, []);

  const fetchModels = async () => {
    const userId = Office.context.roamingSettings.get("karlamail_uid");
    const modelsData = await getModels({ uid: userId });
    setModels(modelsData);
  };

  const handleAuthenticationCallback = async () => {
    setAuthenticated(true);
    await fetchModels();
    setFormVisible(false);
  };

  async function getConversation(): Promise<any | null> {
    try {
      const conversationId = Office.context.mailbox.item.conversationId;
      const token: string = Office.context.roamingSettings.get("msal_access_token");
      console.log(token);
      const getConversationIdUrl: string = `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`;
      const response = await axios.get(getConversationIdUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data && response.data.value && response.data.value.length > 0) {
        console.log("Response", response);
        return response.data.value[0];
      }
    } catch (e) {
      console.error("Error when getting conversation", e);
    }
  }
  const handleWriteSuggestion = async () => {
    setWriteAnswerButtonText("Hold on...");
    setWarning({ reason: "Generating answer" });
    try {
      const conversation: any = await promptAnswerSuggestion();
      setConversation(conversation);
      setAnswerHtml(conversation?.messages?.at(1).content);
      setWarning(null); //Prevent snackbar overlay
      setMessage({ reason: "Answer generated successfully" });
    } catch (e) {
      displayError(e.message);
    } finally {
      setWriteAnswerButtonText("Write answer suggestion");
    }
  };

  const handleResetGraph = () => {
    logout();
  };

  const handleResetAuth = () => {
    // Remove all authentication variables
    Office.context.roamingSettings.remove("authentication_form_api_key");
    Office.context.roamingSettings.remove("karlamail_uid");
    Office.context.roamingSettings.remove("authentication_form_secret_key");
    Office.context.roamingSettings.remove("straact_access_token");
    Office.context.roamingSettings.remove("karlamail_models");
    Office.context.roamingSettings.remove("karlamail_model");
    setModels([]); // Remove models
    setAnswerHtml(null);
    setConversation(null);

    Office.context.roamingSettings.saveAsync((result) => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        console.error(`Action failed with message: ${result.error.message}`);
        displayError("Failed to reset authentication");
      } else {
        console.log(`Settings saved with status: ${result.status}`);
        setFormVisible(true);
        setAuthenticated(false);
        setSettingsVisible(false);
        setMessage({ reason: "Authentication has been reset" });
      }
    });
  };

  const handleRefine = (conversation) => {
    if (conversation) {
      setConversation(conversation);
      setAnswerHtml(conversation?.messages?.at(conversation?.messages?.length - 1).content);
      setMessage({ reason: "Successfully refined message" });
    } else {
      console.error("Failed to refine answer");
      displayError("Failed to refine answer");
    }
  };

  const handleToggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  useEffect(() => {
    if (graphAuthenticatorInitialized && authenticated) {
      fetchModels();
    }
  }, [graphAuthenticatorInitialized, authenticated]);
  //
  useEffect(() => {
    if (graphAuthenticatorInitialized) {
      if (authenticated) {
        fetchModels();
      }
    }
  }, [authenticated]);

  const initialCheckDone = useRef(false);

  useEffect(() => {
    const autoAuthenticate = async () => {
      const authenticationService = new AuthenticationService();
      try {
        await authenticationService.authenticate("", "", () => {}, false);
        const authGranted = await authenticationService.checkAuthentication();

        if (authGranted) {
          setAuthenticated(true);
          setMessage({ reason: "Successfully authenticated" });
          await fetchModels().then(async (response) => {
            setFormVisible(false);
            await handleWriteSuggestion();
          });
        }
      } catch (e) {
        console.error("An error occured while authenticating");
        displayError("An error occuredd while authenticating");
      }
      initialCheckDone.current = true;
    };
    if (graphAuthenticated) {
      autoAuthenticate();
    }
  }, []);
  return (
    <>
      <div id="banner-wrapper">
        <Banner onToggleSettings={handleToggleSettings}></Banner>
      </div>
      <hr className="solid"></hr>
      {settingsVisible && (
        <div id="settings-menu">
          <SettingsMenu
            models={models}
            onResetAuth={handleResetAuth}
            onResetGraph={handleResetGraph}
            graphAuthenticated={graphAuthenticated}
            karlaAuthenticated={authenticated}
          ></SettingsMenu>
        </div>
      )}
      <div id="meta-data">
        <MetaData item={item}></MetaData>
      </div>
      <hr className="dotted"></hr>

      <div id="graph-authentication-wrapper">
        {!graphAuthenticated && (
          <div>
            <p>To generate meaningful and relevant answers, Karla needs access to read emails.</p>
            <p>You must grant access with the same microsoft account as the one you're using in outlook.</p>
            <br></br>
            <PrimaryButton className="success-btn" text="Grant Access" handleClick={login}></PrimaryButton>
          </div>
        )}
      </div>
      <div id="authentication-wrapper">
        {graphAuthenticated && (
          <AuthenticationForm
            isVisible={formVisible}
            onAuthenticated={handleAuthenticationCallback}
          ></AuthenticationForm>
        )}
      </div>
      <div id="write-suggestion-wrapper">
        {graphAuthenticated && !formVisible && (
          <PrimaryButton
            fullWidth={true}
            text={writeAnswerButtonText}
            handleClick={handleWriteSuggestion}
            id="prompt-answer-suggestion"
          />
        )}

        <div id="answer-window-wrapper">
          <hr className="dotted"></hr>
          {answerHtml && (
            <div id="suggested-prompt-answer">
              <div id="refine-suggestion-wrapper"></div>
              <Answer answerHtml={answerHtml} conversation={conversation} onRefine={handleRefine}></Answer>
            </div>
          )}
        </div>
        <div id="feedback">
          {error && <DisplayError display_message={error} snackbarHandleClose={() => setError(null)}></DisplayError>}
          {!error && warning && (
            <DisplayWarning display_message={warning} snackbarHandleClose={() => setWarning(null)}></DisplayWarning>
          )}
          {!error && !warning && message && (
            <DisplaySuccess display_message={message} snackbarHandleClose={() => setMessage(null)}></DisplaySuccess>
          )}
        </div>
      </div>
    </>
  );
};

export default Root;
