import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React from "react";
import { useTranslation } from "react-i18next";

type DisplayErrorProps = {
  display_message: { reason: string } | null;
  snackbarHandleClose: () => void;
};

const DisplayError = ({ display_message, snackbarHandleClose }: DisplayErrorProps) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={true}
      autoHideDuration={8000}
      onClose={snackbarHandleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={snackbarHandleClose} severity="error" sx={{ width: "100%" }}>
        {t(display_message?.reason ?? "")}
      </Alert>
    </Snackbar>
  );
};

export default DisplayError;
