import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";

const Banner: React.FC<{ onToggleSettings: () => void }> = ({ onToggleSettings }) => {
  return (
    <>
      <img id="karla-logo" src="/assets/karla-logo.png" alt="karla-logo"></img>
      <IconButton aria-label="settings" onClick={onToggleSettings}>
        <SettingsIcon></SettingsIcon>
      </IconButton>
    </>
  );
};

export default Banner;
